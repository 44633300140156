import scope from '../scope';
import Base from '../libs/view';
import Highlight from './components/highlight';
import Showcase from './components/showcase';
import Posts from './components/posts';
import Testimonials from './components/testimonials';
import Search from './components/search';

scope.views.Home = Base.extend({
    name: 'home',
    ui: {
        banner: ".banner"
    },
    events: {},
    initialize: function () {
        //iniciando
        console.log('inicia home');
        scope.login_redirect = null;
    },
    onAttach: function () {
        //manipular dom
        console.log('manipula home', this.ui.banner);
        console.log(this);
    },
});

export default scope.views.Home;

